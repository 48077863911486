import React from 'react';
import "./facilities.css"
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import Bar from '../bar';
import FacilitiesCard from './components/facilities-card';

const Facilities = () => {
    const articles = [
        {
            key: 1,
            title: "Special Education",
            description:
                `Rehabilitation Council of India approved National Conference (Continuing Rehabilitation Education (CRE) programme) was conducted in Jyothis Special School by Composite Regional Centre (CRC), Kozhikode. The conference was conducted on 6th, 7th and 8th of January 2020 based on the topic “Advances In Auditory Electro Physiological Evaluation”.`,
            url: "",
            image: "Event1.jpg",
            details: []
        },
        {
            key: 2,
            title: "Special Education",
            description:
                `Jyothis Special School and Therapy Centre hosted Baskyamos Fellowship Meet 2021-2022 of Chengannur- Mavelikkara Diocese. Rt. Rev. Thomas Mar Timotheos Episcopa was the chief guest of the meeting.
                `,
            url: "",
            image: "Event2.jpg",
            details: []
        },
        {
            key: 3,
            title: "Special Education",
            description:
                `Homeland Fellowship of St.Thomas Mar Thoma Church, Kuwait conducted at Jyothis Special School and Therapy Centre on July 22,2022.
                `,
            url: "",
            image: "Event3.jpg",
            details: []
        },
        {
            key: 4,
            title: "Special Education",
            description:
                `Mar Thoma Suvishesha Sevika Sanghom (Mavelikkara Center) Retreat was conducted at Jyothis Special School and Therapy Centre on June 14,2022.
                `,
            url: "",
            image: "Event4.jpg",
            details: []
        },
        {
            key: 5,
            title: "Special Education",
            description:
                `Kaisthana Samithi retreat of Thittamel Trinity Mar Thoma Church conducted at Jyothis Special School and Therapy Centre on July 23,2022
                `,
            url: "",
            image: "Event5.jpg",
            details: []
        },

    ]

    return (
        <div className='main-container' style={{ paddingTop: 30 }}>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Grid container spacing={3} style={{ paddingBottom: 20 }} alignItems="center" direction="column">
                    <Typography style={{ display: "block" }} variant="h5">Facilities</Typography>
                    <Bar />
                </Grid>
            </Box>
            <div className="facilities-container body-font">
                <div className="facilities-row">

                    <div className="facilities-service">
                        <i className="fas fa-users"></i>
                        <h2>Conferences</h2>
                        <p>
                            We host conferences and can provide facilities for a total of 200 attendees.
                        </p>
                    </div>
                    <div className="facilities-service">
                        <i className="fas fa-chalkboard-teacher"></i>
                        <h2>Classes</h2>
                        <p>
                            The school also arranges dance and marching band classes
                        </p>
                    </div>
                    <div className="facilities-service">
                        <i className="fas fa-photo-video"></i>
                        <h2>Audio-Visual Room</h2>
                        <p>
                            We are proud to have an audio-visual room that can be used for educational and entertainment activities of the children.
                        </p>
                    </div>
                    <div className="facilities-service">
                        <i className="fas fa-birthday-cake"></i>
                        <h2>Celebrations</h2>
                        <p>
                            The school also welcome people who would like to celebrate special occasions of their life with our children.
                        </p>
                    </div>
                    <div className="facilities-service">
                        <i className="fas fa-user-md"></i>
                        <h2>Therapy centers</h2>
                        <p>
                            Well equipped Physiotherapy and Speech therapy centers
                        </p>
                    </div>
                    <div className="facilities-service">
                        <i className="fas fa-car"></i>
                        <h2>Parking</h2>
                        <p>
                            Ample Parking facility
                        </p>
                    </div>
                </div>
            </div>

            <Grid container spacing={3} style={{ paddingBottom: 50, paddingTop: 50 }} alignItems="center" direction="column">
                <Typography style={{ display: "block" }} variant="h5">Events</Typography>
                <Bar />
            </Grid>

            <Grid container spacing={3} style={{ textAlign: "center" }} direction="row" justifyContent="center">

                {articles.map(article => (
                    <Grid item xs={12} sm={4} key={article.key} >
                        <FacilitiesCard article={article} />
                    </Grid>
                ))}
            </Grid>

        </div>
    )
}

export default Facilities