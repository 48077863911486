import React from "react"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Image from "../../image"

interface IProps {
  article: Article
}

interface IDetails {
  heading: string
  text: string
  list: []
}

interface Article {
  title: string
  image: string
  description: string
  url: string
  details: IDetails[]
}

const FacilitiesCard = ({ article, ...props }: IProps) => {

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card style={{ minHeight: "600px" }} className="body-font" >
      <CardMedia image={article.image}>
        <Image alt={article.title} filename={article.image} />
      </CardMedia>
      <CardContent style={{ textAlign: "start" }}>
        <Typography align="justify" variant="body2" component="p" className='body-font'>
          {article.description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default FacilitiesCard
